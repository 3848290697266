import React, { Suspense } from "react";
import TimeUtilLoader from "../TimeUtilizationLoader/TimeUtilLoader";
const LineChart = React.lazy(() => import("./LineChart"));
function LineChartContainer() {
  return (
    <Suspense fallback={<TimeUtilLoader />}>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="timeUtilizationContentContainer">
            <div id="chart">
              <LineChart />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
export default LineChartContainer;
