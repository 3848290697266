import React ,{useEffect}from "react";
import LineChartContainer from "../TimeUtilizationCharts/LineChartContainer";
import AccordionWithPieChartContainer from "./AccordionWithPieChartContainer";
import { useTimeUtilizationContext } from "../TimeUtilizationResponseProvider";
function ChartsContainer(data: any) {
  
  const { setRes } = useTimeUtilizationContext();
  useEffect(() => {
    setRes(data)
  }, [data, setRes])
  return (
    <React.Fragment>
      <LineChartContainer />
      <AccordionWithPieChartContainer />
    </React.Fragment>
  );
}
export default ChartsContainer;
