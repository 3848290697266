import React, { Suspense } from "react";
import TimeUtilLoader from "../TimeUtilizationLoader/TimeUtilLoader";
import "../../../css/timeUtilizationStyles.css";
import ChartTitle from "../TimeUtilizationCharts/ChartTitle";
const AccordionContainer = React.lazy(
  () => import("../TimeUtilizationAccordion/AccordionContainer")
);
const PieChart = React.lazy(() => import("../TimeUtilizationCharts/PieChart"));
function AccordionWithPieChartContainer() {
  return (
    <div className="row pie-chart-accordion-container">
      <div className="mt-2 col-sm-12 col-md-6 col-lg-6 h-100">
        <Suspense fallback={<TimeUtilLoader />}>
          <div className="h-100 timeUtilizationContentContainer">
            <div className="pie-chart-container h-100">
              <PieChart />
            </div>
          </div>
        </Suspense>
      </div>
      <div className="mt-2 col-sm-12 col-md-6 col-lg-6 h-100">
        <Suspense fallback={<TimeUtilLoader />}>
          <div className="h-100 timeUtilizationContentContainer">
            <div className="Accordion-container h-100">
              <ChartTitle mainTitle={"Application per hours"} />
              <AccordionContainer />
            </div>
          </div>
        </Suspense>
      </div>
    </div>
  );
}
export default AccordionWithPieChartContainer;
